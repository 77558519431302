import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import ArticleLayout from "../components/layout/ArticleLayout";
import PageLayout from "../components/page-layout/PageLayout";

type BlogTemplateProps = {
  pageContext: {
    meta: {
      slug: string;
      title: string;
      description: string;
      date_published: string;
      date_modified: string;
      image: string;
      keywords: string[];
      category: string;
      metaTitle: string;
      author: {
        name: string;
        image: string;
      };
      timeToRead: number;
      related: {
        title: string;
        slug: string;
        date_modified: string;
        timeToRead: number;
      }[];
    };
    html: string;
  };
};

const BlogTemplate: FunctionComponent<BlogTemplateProps> = ({
  pageContext,
}) => {
  const image =
    require(`../assets/images/blog/${pageContext.meta.image}`).default;

  return (
    <PageLayout pageName={pageContext.meta.slug} headerBg="gray-100">
      <SEO
        title={pageContext.meta.metaTitle ?? pageContext.meta.title}
        description={pageContext.meta.description}
        datePublished={new Date(pageContext.meta.date_published)}
        dateModified={new Date(pageContext.meta.date_modified)}
        image={image}
        article={{
          category: pageContext.meta.category,
          keywords: pageContext.meta.keywords,
          headline: pageContext.meta.title,
        }}
      />

      <ArticleLayout
        tag={pageContext.meta.category}
        {...pageContext.meta}
        {...pageContext}
        image={image}
        related={pageContext.meta.related.map((r) => ({
          ...r,
          path: `/blog/${r.slug}`,
        }))}
      />
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BlogTemplate;
